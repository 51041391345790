import React, { useContext, useEffect, useState } from 'react';
import { GlobalStore } from '../../../StateStores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Input, Label, Row, Spinner } from 'reactstrap';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { createUserAcc, getUserGroupsList } from '../../../Actions/Users/UsersActs';
import { toast } from 'react-toastify';
import { CHANGE_USER_CURRENT_GROUP, INACTIVE_USER } from '../../../Constants/PermissionTypes';
import { userCan } from '../../../Helpers/permissionHandler';

const CreateUser = ({selectedUser,toggleModal,refreshList})=>{
    const[roles,setRoles] = useState([])
    const [form,setForm] = useState({
        rolename: '',
        username:'',
        fname:'',
        mname:'',
        lname:'',
        email:'',
        roleid:4,
        gender:'',
        mobile:'',
        status:'active'});
    const [formErrors,setFormErrors] = useState({
        username: 'Username is required',
        fname: 'First name is required',
        mname: 'Middle name is required',
        lname: 'Last name is required',
        gender: 'Gender is required',
        mobile: 'Mobile is required',
        roleid: 'Usergroup is required',
        status: 'Status is required',
    });
    const [formValid, setFormValid] = useState(false);

    const {
        UsersDispatch,
        UsersState:{
            postUserStates:{data,error,loading},
            getUserGroupsStates:{data:roleData,loading:roleLoading},
        }} = useContext(GlobalStore);


    useEffect(()=>{
        const loadUserGroups = async ()=>{
            try {
                let response = await getUserGroupsList({})(UsersDispatch);
                setRoles(response.data)
            } catch (error) {
                toast.error(error.message)
            }
        }
        loadUserGroups()
    },[])

    useEffect(() => {
        // Check if there are any errors in the form
        const isValid = Object.values(formErrors).every(item => !item);
        setFormValid(isValid);
    }, [formErrors]);

    useEffect(() => {
        if (selectedUser && Object.keys(selectedUser).length > 0) {
            setForm({
                id: selectedUser.id || '',
                username: selectedUser.username || '',
                fname: selectedUser.fname || '',
                mname: selectedUser.mname || '',
                lname: selectedUser.lname || '',
                email: selectedUser.email || '',
                gender: selectedUser.gender || '',
                mobile: selectedUser.mobile || '',
                roleid: selectedUser.roleid || '',
                status: selectedUser.status || '',
            });

            setFormErrors({
                username: '',
                fname: '',
                mname: '',
                lname: '',
                gender: '',
                mobile: '',
                roleid: '',
                status: '',
            });
        }
    }, [selectedUser]);


    const onChange = ({name,e}) => {
        let value;
        if(name === 'mobile'){
            value = e;
        }else{
            value = e.target.value;
        }

        setForm((prev)=> ({...prev,[name]:value}));

        if(value !== ''){

            setFormErrors((prev) => {
            return {...prev, [name]: null};
            });

        }else{

            setFormErrors((prev) => {
                return {...prev, [name]: 'This field is required'};
            });

        }
    }

    const onSubmit = async()=>{
        if(formValid){
            try {
                const response = await createUserAcc(form)(UsersDispatch);
                toast.success(response.message);
                toggleModal()

                if(response.status) refreshList();
                
            } catch (error) {
                toast.error(error.message)
            }
        }
    }

    return(
        <React.Fragment>
            <Row>
                <Col md={4}>
                    <Label>User name</Label>
                    <Input
                        value={form?.username}
                        onChange={(e) => onChange({name:'username',e})}
                        type="text"  
                        className="rounded-0"
                        placeholder="User name" 
                        valid={formErrors.username === null}
                        invalid={formErrors.username !== null}/>
                        <small className="text-danger">{formErrors.username}</small>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col>
                    <Label>Frist name</Label>
                    <Input
                        value={form?.fname}
                        onChange={(e) => onChange({name:'fname',e})}
                        type="text"  
                        className="rounded-0"
                        placeholder="Enter first name" 
                        valid={formErrors.fname === null}
                        invalid={formErrors.fname !== null}/>
                        <small className="text-danger">{formErrors.fname}</small>
                </Col>
                <Col>
                    <Label>Middle name</Label>
                    <Input
                        value={form?.mname}
                        onChange={(e) => onChange({name:'mname',e})}
                        type="text"  
                        className="rounded-0"
                        placeholder="Enter a middle name" 
                        valid={formErrors.mname === null}
                        invalid={formErrors.rolename !== null}/>
                        <small className="text-danger">{formErrors.mname}</small>
                </Col>
                <Col>
                    <Label>Last name</Label>
                    <Input
                        value={form?.lname}
                        onChange={(e) => onChange({name:'lname',e})}
                        type="text"  
                        className="rounded-0"
                        placeholder="Enter a last name" 
                        valid={formErrors.lname === null}
                        invalid={formErrors.lname !== null}/>
                        <small className="text-danger">{formErrors.lname}</small>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col>
                    <Label>Gender</Label>
                    <Input
                        value={form?.gender}
                        onChange={(e) => onChange({name:'gender',e})}
                        type="select"  
                        className="rounded-0"
                        placeholder="Enter first name" 
                        valid={formErrors.gender === null}
                        invalid={formErrors.gender !== null}>
                            <option>--Select--</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </Input>
                        <small className="text-danger">{formErrors.gender}</small>
                </Col>
                <Col>
                    <Label>Mobile</Label>
                    <PhoneInput 
                        defaultCountry="TZ"
                        className="form-control rounded-0"
                        valid={formErrors.mobile === ''}
                        invalid={formErrors.mobile !== ''}
                        value={form?.mobile || ""}
                            onChange={(e) => onChange({name:'mobile',e})}
                    />
                    <small className="text-danger">{formErrors.mobile}</small>
                </Col>
                <Col>
                    <Label>Email</Label>
                    <Input
                        value={form?.email}
                        onChange={(e) => onChange({name:'email',e})}
                        type="text"  
                        className="rounded-0"
                        placeholder="Enter a email" 
                        // valid={formErrors.email === null}
                        // invalid={formErrors.email !== null}
                        />
                        <small className="text-danger">{formErrors.email}</small>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col md={4}>
                    <Label>User group / Role</Label>
                    <Input
                        value={form?.roleid}
                        onChange={(e) => onChange({name:'roleid',e})}
                        type="select"  
                        className="rounded-0"
                        disabled={!userCan(CHANGE_USER_CURRENT_GROUP)}
                        placeholder="Role / Usergroup" 
                        valid={formErrors.roleid === null}
                        invalid={formErrors.roleid !== null}>
                            <option>--Select--</option>
                            {(roles || []).map((role,index)=>(
                                <option value={role.id}>{role.rolename}</option>
                            ))}
                    </Input>
                    <small className="text-danger">{formErrors.roleid}</small>
                </Col>
                <Col md={4}>
                    <Label>User group / Role</Label>
                    <Input
                        value={form?.status}
                        onChange={(e) => onChange({name:'status',e})}
                        type="select"  
                        className="rounded-0"
                        disabled={!userCan(INACTIVE_USER)}
                        placeholder="Status" 
                        valid={formErrors.status === null}
                        invalid={formErrors.status !== null}>
                            <option>--Select--</option>
                            <option value="active">Active</option>
                            <option value="inactive">In-Active</option>
                    </Input>
                    <small className="text-danger">{formErrors.status}</small>
                </Col>
            </Row>
            <div className='d-flex justify-content-end'>
                <Button 
                    disabled={!formValid}
                    color="dark" 
                    className="custom-button rounded-0"
                    size="sm"
                    onClick={onSubmit}>
                        {loading && <Spinner size="sm"/>}
                        {!loading && <FontAwesomeIcon icon={faSave}/>} {`${form?.id?'Update':'Save'}`}
                </Button>
            </div>
        </React.Fragment>
    )
}

export default CreateUser;