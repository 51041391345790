import React from 'react'
import { Col, Container, Row } from 'reactstrap';

const Basic503 = ()=>{
    return(
        <React.Fragment>
            <div className="App">
                <Container fluid className="vh-100 d-flex align-items-center justify-content-center gx-0">
                    <Row className="w-100 gx-0" style={{ height: '100%' }}>
                        {/* Left Section */}
                        <Col className="p-0 black-background d-flexd align-items-centerd">
                            <div className="cover-images w-100 d-flex justify-content-center h-100s">
                                <img src="/logo-black-bg.png" alt="Cover" className="img-fluid h-100d" />
                            </div>
                            <div style={{ textAlign: 'center', padding: '20px' }}>
                                <h1 className='text-yellow-brand-primary'>You're not authorized to access this resource</h1>
                                <p className='text-yellow-brand-primary'>It seems that you don't have the necessary permissions to view this page.</p>
                                <p className='text-yellow-brand-primary'>
                                    Please contact our administrator 
                                    {/* or{' '} */}
                                    {/* <Link to="/">go back to the home page</Link>. */}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Basic503;