import React from 'react';
import EditActionButton from './EditActionButton';
import TicketTypesAction from './TicketTypesAction';
import SellTicketAction from './SellTicketAction';
import EventTemplateAction from './EventTemplateAction';
import EventTablesAction from './EventTablesAction';
import EventMenuItemAction from './EventMenuItemAction';
import EventAccessManagerAction from './EventAccessManagerAction';
import { userCan } from '../../Helpers/permissionHandler';
import { ACCESS_EVENT_GUEST_MANAGER, CREATE_EVENT_MENU, CREATE_EVENT_SEATING_TABLE, CREATE_TICKET_TYPE, EDIT_EVENT, SET_EVENT_CARD_TEMPLATE } from '../../Constants/PermissionTypes';

const ActionButtons = ({
    event,
    selectedCategory, 
    setSelectedCategory,})=>{

    return(
        <React.Fragment>
            <div className='d-flex'>

                {userCan(EDIT_EVENT) ? (
                    <EditActionButton 
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>
                ) : null}

                {userCan(CREATE_TICKET_TYPE) ? (
                    <TicketTypesAction
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>
                ) : null}

                <SellTicketAction 
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>

                {userCan(SET_EVENT_CARD_TEMPLATE) ? (
                    <EventTemplateAction 
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>
                ) : null}

                {userCan(CREATE_EVENT_SEATING_TABLE) ? (
                    <EventTablesAction
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>
                ):null}

                {userCan(CREATE_EVENT_MENU) ? (
                    <EventMenuItemAction
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>
                ) : null}

                {userCan(ACCESS_EVENT_GUEST_MANAGER) ? (
                    <EventAccessManagerAction
                    clickHandler='button'
                    event={event} 
                    selectedCategory={selectedCategory} 
                    setSelectedCategory={setSelectedCategory}/>
                ) : null}
            </div>
        </React.Fragment>
    )
}

export default ActionButtons;