import { 
    GET_SEARCH_USER_FAIL, 
    GET_SEARCH_USER_LOADING, 
    GET_SEARCH_USER_SUCCESS, 
    GET_USERS_FAIL, 
    GET_USERS_LOADING,
    GET_USERS_SUCCESS,
    GET_USER_GROUPS_FAIL,
    GET_USER_GROUPS_LOADING,
    GET_USER_GROUPS_SUCCESS,
    GET_USER_GROUP_RIGHTS_FAIL,
    GET_USER_GROUP_RIGHTS_LOADING,
    GET_USER_GROUP_RIGHTS_SUCCESS,
    POST_USERS_BULK_UPLOAD_FAIL,
    POST_USERS_BULK_UPLOAD_LOADING,
    POST_USERS_BULK_UPLOAD_SUCCESS,
    POST_USER_FAIL,
    POST_USER_GROUP_RIGHTS_FAIL,
    POST_USER_GROUP_RIGHTS_LOADING,
    POST_USER_GROUP_RIGHTS_SUCCESS,
    POST_USER_LOADING,
    POST_USER_SUCCESS} from "../../Constants/ActionTypes";
import AxiosHelper from "../../Helpers/AxiosHelper";

export const getSearchUsersAct = ({param}) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_SEARCH_USER_LOADING
        })
    
        AxiosHelper.get(`/users/search?q=${param}`).then(
            (res) => {
                
                dispatch({
                    type:GET_SEARCH_USER_SUCCESS,
                    payload:res.data.data,
                });
                resolve(res.data);
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_SEARCH_USER_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })

}


export const getUsersList = ({from_date="",to_date=""}) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_USERS_LOADING
        })
    
        AxiosHelper.get(`/users`,{
            params:{
                from_date,
                to_date
            }
        }).then(
            (res) => {
                
                dispatch({
                    type:GET_USERS_SUCCESS,
                    payload:res.data.data,
                });
                resolve(res.data);
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_USERS_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })

}

export const getUserGroupsList = ({}) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_USER_GROUPS_LOADING
        })
    
        AxiosHelper.get(`/users/user-groups`).then(
            (res) => {
                
                dispatch({
                    type:GET_USER_GROUPS_SUCCESS,
                    payload:res.data.data,
                });
                resolve(res.data);
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_USER_GROUPS_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })
}

export const getUserGroupRights = ({role_id}) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:GET_USER_GROUP_RIGHTS_LOADING
        })
    
        AxiosHelper.get(`/users/user-group-rights`,{
            params:{
                role_id:role_id
            }
        }).then(
            (res) => {
                
                dispatch({
                    type:GET_USER_GROUP_RIGHTS_SUCCESS,
                    payload:res.data.data,
                });
                resolve(res.data);
            }
        ).catch(
            (err) => {
                dispatch({
                    type:GET_USER_GROUP_RIGHTS_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })

}

/**
 * Action used to create role
 * @param {JSON} payload 
 * @returns 
 */
export const postUserGroupAct =  (payload) => (dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:POST_USER_GROUP_RIGHTS_LOADING
        });
    
        AxiosHelper.post('/users/user-group-rights',{payload}).then(
            (res) => {
    
                dispatch({
                    type:POST_USER_GROUP_RIGHTS_SUCCESS,
                    payload:res.data,
                });
                resolve(res.data);
            }
            
        ).catch(
            (err) => {
                dispatch({
                    type:POST_USER_GROUP_RIGHTS_FAIL,
                    payload:err.response
                    ? err.response.data
                    : {error:'Something went wrong, try again'},
                })
                reject(err)
            }
        )
    })
}

export const createUserAcc = (payload) =>(dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:POST_USER_LOADING
        })
    
        AxiosHelper.post('/users/',{payload}).then(
            (res) => {
                resolve(res.data);
    
                dispatch({
                    type:POST_USER_SUCCESS,
                    payload:res.data.data,
                });
            }
        ).catch(
            (err) => {
                dispatch({
                    type:POST_USER_FAIL,
                    payload:err?.response?.data,
                });
                reject(err)
            }
        )
    })

}


export const postBulkUsersAct =  (payload) => (dispatch)=>{
    return new Promise((resolve,reject)=>{
        dispatch({
            type:POST_USERS_BULK_UPLOAD_LOADING
        });
    
        AxiosHelper.post('/users/bulk-upload',payload).then(
            (res) => {
    
                dispatch({
                    type:POST_USERS_BULK_UPLOAD_SUCCESS,
                    payload:res.data,
                });
                resolve(res.data);
            }
            
        ).catch(
            (err) => {
                dispatch({
                    type:POST_USERS_BULK_UPLOAD_FAIL,
                    payload:err.response
                    ? err.response.data
                    : {error:'Something went wrong, try again'},
                })
                reject(err)
            }
        )
    })
}