import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../Layouts/Header';
import { Badge, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledDropdown } from 'reactstrap';
import BreadCrumb from '../../Common/BreadCrumb';
import { GlobalStore } from '../../../StateStores';
import { toast } from 'react-toastify';
import { getEventsAct } from '../../../Actions/Masters/EventCategoriesActs';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faClipboardList, faEdit, faEllipsis, faEye, faPizzaSlice, faPlus, faStickyNote, faTable, faTicket, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import EventCreate from './EventCreate';
import EventTicketTypesForm from './EventTicketTypesForm';
import InvitationTemplateViewer from '../InvitationTemplates/InvitationTemplateViewer';
import UserTicketsForm from './OrderTicketsForm';
import DateFilter from '../../Common/DateFilter';
import EventAccessManagerAction from '../../ActionButtons/EventAccessManagerAction';
import EventAccessManager from '../EventAccessManager';
import { userCan } from '../../../Helpers/permissionHandler';
import { ACCESS_EVENT_DASHBOARD, ACCESS_EVENT_GUEST_MANAGER, ASSIGN_EVENT_CARD_TO_GUEST, CREATE_EVENT_MENU, CREATE_EVENT_SEATING_TABLE, CREATE_TICKET_TYPE, DELETE_EVENT, EDIT_EVENT, SET_EVENT_CARD_TEMPLATE } from '../../../Constants/PermissionTypes';
import EventTables from '../EventTables';
import EventMenuManager from '../FoodMenuManager/EventMenuManager';

const EventMasterList = ()=>{
    const [eventList, setEventList] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState({});
    const [modal, setModal] = useState(false);
    const [modalFullscreen, setModalIsFullscreen] = useState(false);
    const [currentView, setCurrentView] = useState('event');
    const [currentViewTitle, setCurrentViewTitle] = useState('');
    const [selectedCategory, setSelectedCategory] = useState({});
    const [form,setForm] = useState({
            event_name:'',
            from_date:moment().startOf('month'),
            to_date:moment().endOf('month')});

    const {
        MasterDispatch,
        MasterState:{
            getEvents:{loading}
        }} = useContext(GlobalStore);


    const fetchEventList = async ()=>{
        try {
            let response = await getEventsAct(form)(MasterDispatch);
            if(response.status === 'Success'){
                setEventList(response.data)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(()=>{
        fetchEventList()
    },[form])

    const togOpenModal = (type)=>{
        setCurrentView(type);
        setModal(!modal);
    }


    const setlectEventAct = ({event,type,viewTitle,modalIsFullscreen=false})=>{
        setSelectedEvent(event)
        togOpenModal(type)
        setModalIsFullscreen(modalIsFullscreen)
        setCurrentViewTitle(viewTitle)
    }

    const onCloseModal = ()=>{
        setSelectedEvent({})
        setCurrentView('event');
    }

    const onChange = ({name,e}) => {
        let value;
        if(name === 'from_date' || name === 'to_date' || name === 'contact_mobile'){
            value = e;
        }else{
            value = e.target.value;
        }

        setForm((prev) => ({ ...prev, [name]: value }));

        /*if (value) {
            setFormErrors(prev => ({ ...prev, [name]: '' }));
        } else {
            setFormErrors(prev => ({ ...prev, [name]: 'This field is required' }));
        }*/

    }

    return(
        <React.Fragment>
            <Header/>
            <div className="wrapper-top"></div>

            <Modal
            onClosed={(e)=>onCloseModal()}
            fullscreen={modalFullscreen}
            className='custom-modal rounded-0 border-0' size='xl' isOpen={modal} scrollable={true} toggle={() => { togOpenModal() }}>
                <ModalHeader className='yellow-background rounded-0 border-0' tag="div" toggle={() => { togOpenModal() }}>
                    <h5 className='mb-0'>
                        <FontAwesomeIcon icon={selectedEvent.id? faEdit:faPlus}/> 
                        <span className='ms-1'>{currentViewTitle}</span> {selectedCategory?.name && `- ${selectedCategory?.name}`}</h5>
                </ModalHeader>
                <ModalBody className='rounded-0'>
                    <Row className='g-3 p-3'>
                        <Col>

                        {currentView === 'event' && (
                            <EventCreate
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                                ctrlModal={togOpenModal}
                                selectedEvent={selectedEvent}
                                />
                        )}
                        
                        {currentView === 'ticket_types' && (
                            <EventTicketTypesForm
                                selectedEvent={selectedEvent}
                            />
                        )}

                        {currentView === 'sell_ticket' && (
                            <UserTicketsForm
                                selectedEvent={selectedEvent}
                            />
                        )}

                        {currentView === 'event_template' && (
                            <InvitationTemplateViewer 
                                selectedEvent={selectedEvent}/>
                        )}

                        {currentView === 'event_access_manager' && (
                            <EventAccessManager 
                                selectedEvent={selectedEvent}/>
                        )}

                        {currentView === 'event_seating_manager' && (
                            <EventTables selectedEvent={selectedEvent}/>
                        )}

                        {currentView === 'event_menu_manager' && (
                            <EventMenuManager selectedEvent={selectedEvent}/>
                        )}
                            
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </Modal>


            <Container fluid>
                <BreadCrumb 
                    title="Master list"
                    subTittle="Event master list"
                    pageTitle="Event"/>

                <Card className="border-0 mt-3">
                    <CardHeader>
                        <DateFilter 
                            startDate={form?.from_date}
                            endDate={form?.to_date}
                            onDatesChange={({ startDate, endDate }) => {
                                onChange({name:'from_date',e:startDate});
                                onChange({name:'to_date',e:endDate})
                            }}
                        />
                    </CardHeader>
                    <CardBody className=" rounded-1 p-1">
                        <Table size='sm' className=''>
                            <thead className='bg-light'>
                                <tr>
                                    <th>#</th>
                                    <th>Event name</th>
                                    <th>Event date</th>
                                    <th>Event status</th>
                                    <th>Contact personal</th>
                                    <th>Category</th>
                                    <th>Location</th>
                                    <th>Listed by</th>
                                    <th>Approval Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(eventList || []).map((item,index)=>(
                                    <tr key={item.id}>
                                        <td>{++index}</td>
                                        <td>{item.title}</td>
                                        <td>{`${moment.utc(item.from_date).format('DD-MM-YYYY')} - ${moment.utc(item.to_date).format('DD-MM-YYYY')}`}</td>
                                        <td><small className='text-muted'>{item.event_status}</small></td>
                                        <td>
                                            <div className='fs-11'>{item.contact_name}</div>
                                            <div><small className='text-muted'>{item.contact_mobile}</small></div>
                                        </td>
                                        <td>{item.cat_name}</td>
                                        <td>{item.location}</td>
                                        <td>
                                            {item.createdby}
                                            <div className="d-flexs">
                                                <div className='bg-lights text-muted'>
                                                    <small className="text-muted">{moment.utc(item.doc).format('LL')}</small>
                                                    <small className="text-info text-muted ms-1">({moment.utc(item.doc).startOf().fromNow()})</small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <Badge color={item.approved?'success':'danger'}>{item.approved ? 'Approved':'Not yet'}</Badge>
                                        </td>
                                        <td>
                                            <UncontrolledDropdown direction='start' className="me-2">
                                                <DropdownToggle tag="button" size='sm' className="btn btn-soft-secondary btn-sm" role='button'>
                                                    <FontAwesomeIcon icon={faEllipsis} />
                                                </DropdownToggle>
                                                <DropdownMenu>

                                                    {userCan(EDIT_EVENT) ? (
                                                        <DropdownItem 
                                                        onClick={(e) => { setlectEventAct({event:item, type:'event',viewTitle:'Edit event'}) } }
                                                        ><FontAwesomeIcon icon={faEdit} /> Edit event</DropdownItem>
                                                    ):null}

                                                    {userCan(CREATE_TICKET_TYPE) ? (
                                                        <DropdownItem 
                                                        onClick={(e) => { setlectEventAct({event:item, type:'ticket_types',viewTitle:'Ticket Type'}) }} 
                                                        ><FontAwesomeIcon icon={faStickyNote} /> Ticket type</DropdownItem>
                                                    ):null}

                                                    {userCan(ASSIGN_EVENT_CARD_TO_GUEST) ? (
                                                        <DropdownItem 
                                                        onClick={(e) => { setlectEventAct({event:item, type:'sell_ticket',viewTitle:'Assign event card'}) }}
                                                        ><FontAwesomeIcon icon={faTicket} /> Assign card</DropdownItem>
                                                    ):null}
                                                    
                                                    {userCan(ACCESS_EVENT_DASHBOARD) ? (
                                                        <DropdownItem >
                                                            <NavLink
                                                                to="/dashboard/events/viewer"
                                                                state={{ event: item }}
                                                            >
                                                                <FontAwesomeIcon icon={faEye} /> Event dashboard
                                                            </NavLink>
                                                        </DropdownItem>
                                                    ):null}

                                                    {userCan(SET_EVENT_CARD_TEMPLATE) ? (
                                                        <DropdownItem 
                                                            onClick={(e) => { setlectEventAct({event:item, type:'event_template',viewTitle:'Event card template'}) }}
                                                            ><FontAwesomeIcon icon={faClipboardList} /> Card template
                                                        </DropdownItem>
                                                    ):null}

                                                    {userCan(ACCESS_EVENT_GUEST_MANAGER) ? (
                                                        <DropdownItem 
                                                            onClick={(e) => { setlectEventAct({event:item, type:'event_access_manager',modalIsFullscreen:true,viewTitle:'Event access manager'}) }}>
                                                            <FontAwesomeIcon icon={faAddressCard} /> Event Access Manager
                                                        </DropdownItem>
                                                    ):null}

                                                    {userCan(CREATE_EVENT_MENU) ? (
                                                        <DropdownItem 
                                                            onClick={(e) => { setlectEventAct({event:item, type:'event_menu_manager',viewTitle:'Event menu manager'}) }}>
                                                            <FontAwesomeIcon icon={faPizzaSlice} /> Event menu items
                                                        </DropdownItem>
                                                    ):null}

                                                    {userCan(CREATE_EVENT_SEATING_TABLE) ? (
                                                        <DropdownItem 
                                                            onClick={(e) => { setlectEventAct({event:item, type:'event_seating_manager',viewTitle:'Event seating plan'}) }}>
                                                            <FontAwesomeIcon icon={faTable} /> Event seating plan
                                                        </DropdownItem>
                                                    ):null}

                                                    {userCan(DELETE_EVENT) ? (
                                                        <DropdownItem ><FontAwesomeIcon icon={faTrash} /> Delete event</DropdownItem>
                                                    ) : null}

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>

            </Container>

        </React.Fragment>
    )
}

export default EventMasterList;