import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../Layouts/Header';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table, UncontrolledDropdown } from 'reactstrap';
import { GlobalStore } from '../../../StateStores';
import { getUsersList } from '../../../Actions/Users/UsersActs';
import { toast } from 'react-toastify';
import BreadCrumb from '../../Common/BreadCrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsis, faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import CreateUser from './CreateUser';
import { userCan } from '../../../Helpers/permissionHandler';
import { CREATE_USER, EDIT_USER, INACTIVE_USER } from '../../../Constants/PermissionTypes';
import DateFilter from '../../Common/DateFilter';

const UsersList = ()=>{
    const[users,setUsers] = useState([]);
    const[modal, setModal] = useState(false);
    const [selectedUser,setSelectedUser] = useState({})
    const [form,setForm] = useState({
                event_name:'',
                from_date:moment().startOf('month'),
                to_date:moment().endOf('month')});
    
    const {
        UsersDispatch,
        UsersState:{
            getUsersStates:{data,error,loading}
        }} = useContext(GlobalStore);

    const loadUsers = async ()=>{
        try {
            let response = await getUsersList(form)(UsersDispatch);
            setUsers(response.data)
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(()=>{
        loadUsers()
    },[form])

    const togOpenModal = ()=>{
        setModal(!modal);
    }

    const selectUser = (user)=>{
        setSelectedUser(user)
        togOpenModal()
    }

    const onChange = ({name,e}) => {
        let value;
        if(name === 'from_date' || name === 'to_date'){
            value = e;
        }else{
            value = e.target.value;
        }

        setForm((prev) => ({ ...prev, [name]: value }));
    }

    return(
        <React.Fragment>
            <Header/>
            <div className="wrapper-top"></div>

            <Modal
            // onClosed={(e)=>onCloseModal()}
            className='custom-modal rounded-0 border-0' size='xl' isOpen={modal} scrollable={true} toggle={() => { togOpenModal() }}>
                <ModalHeader className='yellow-background rounded-0 border-0' tag="div" toggle={() => { togOpenModal() }}>
                    <h5 className='mb-0'>
                        {/* faEdit */}
                        <FontAwesomeIcon icon={selectedUser.id ? faEdit:faPlus}/>
                        <span className='ms-1'>{`${selectedUser.id ? 'Edit':'Create'}`} User</span></h5>
                </ModalHeader>
                <ModalBody className='rounded-0'>
                    <CreateUser selectedUser={selectedUser} toggleModal={togOpenModal} refreshList={loadUsers}/>
                </ModalBody>
                {/* <ModalFooter></ModalFooter> */}
            </Modal>

            <Container fluid>
                <BreadCrumb 
                    title="Users List"
                    subTittle="List of created users"
                    pageTitle="Users"/>
                
                <Card className="border-0 mt-3">
                    <CardHeader>
                        <div className="d-flex justify-content-between">

                            <DateFilter 
                                startDate={form?.from_date}
                                endDate={form?.to_date}
                                onDatesChange={({ startDate, endDate }) => {
                                    onChange({name:'from_date',e:startDate});
                                    onChange({name:'to_date',e:endDate})
                                }}
                            />

                            {!loading && userCan(CREATE_USER) ? (
                                <Button 
                                    size="sm" 
                                    color="success"
                                    outline
                                    className="rounded-0"
                                    onClick={(e) => togOpenModal()}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            ):null}

                            {loading && (
                                <div className='d-flex justify-content-endd p-3'>
                                    <Spinner color='dark' size="sm"/>
                                </div>
                            )}
                            
                        </div>
                    </CardHeader>
                    <CardBody className="bg-light-f1 rounded-1 p-1">
                        <Table size='sm' className=''>
                            <thead className='bg-light'>
                                <tr>
                                    <td>#</td>
                                    <td>Profile status</td>
                                    <td>ID</td>
                                    <td>Username</td>
                                    <td>Fullname</td>
                                    <td>Gender</td>
                                    <td>Mobile</td>
                                    <td>Email</td>
                                    <td>Created From</td>
                                    <td>Created At</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading && (data || []).map((user, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            <Badge color={user.status === 'active' ? 'success':'danger'}>{user.status}</Badge>
                                        </td>
                                        <td>
                                            <span 
                                                role='button' 
                                                onClick={(e) => { userCan(EDIT_USER) && selectUser(user); } }
                                                className={`${userCan(EDIT_USER) ? 'text-primary':'text-black'}`}
                                                >{user.id}</span>
                                        </td>
                                        <td>{user.username}</td>
                                        <td>{user.fullname}</td>
                                        <td>{user.gender}</td>
                                        <td>{user.mobile}</td>
                                        <td>{user.email}</td>
                                        <td>{user.event_name}</td>
                                        <td>
                                            {user.createdby}
                                            <div className="d-flexs">
                                                <div className='bg-lights text-muted'>
                                                    <small className="text-muted">{moment.utc(user.doc).format('LL')}</small>
                                                    <small className="text-info text-muted ms-1">({moment.utc(user.doc).startOf().fromNow()})</small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <UncontrolledDropdown direction='start' className="me-2">
                                                <DropdownToggle tag="button" size='sm' className="btn btn-soft-secondary btn-sm" role='button'>
                                                    <FontAwesomeIcon icon={faEllipsis} />
                                                </DropdownToggle>
                                                <DropdownMenu>

                                                    {userCan(EDIT_USER) ? (
                                                        <DropdownItem 
                                                        onClick={(e) => { selectUser(user) } }
                                                        ><FontAwesomeIcon icon={faEdit} /> Edit user</DropdownItem>
                                                    ):null}

                                                    {/* {userCan(INACTIVE_USER) ? (
                                                        <DropdownItem ><FontAwesomeIcon icon={faTrash} /> Delete user</DropdownItem>
                                                    ) : null} */}

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        
                    </CardBody>
                </Card>

            </Container>
        </React.Fragment>
    )
}

export default UsersList;