import axios from 'axios';
import axiosRetry from 'axios-retry';
import { ReactSession } from 'react-client-session';
import Swal from 'sweetalert2';
import { AccessLocalToken, UpdateLocalAccessToken } from './LocalService';

ReactSession.setStoreType("sessionStorage");


// export const SERVER_BASE_URL_END_POINT = 'http://localhost:8500/digikadi/api';
// export const SERVER_BASE_URL = 'http://localhost:8500';

// export const EVENT_MENU_ORDER = 'http://localhost:3000/event-menu/';
export const EVENT_MENU_ORDER = 'https://app.digikadi.co.tz/event-menu/';

export const SERVER_BASE_URL_END_POINT = 'https://api.digikadi.co.tz';
export const SERVER_BASE_URL = 'https://api.digikadi.co.tz';

const AxiosHelper = axios.create({
    baseURL:SERVER_BASE_URL_END_POINT
});

// Enable retry on network errors
axiosRetry(AxiosHelper, {
    retries: 3, // Number of retries
    retryCondition: (error) => {
        // Retry only on network errors
        return error.code === 'ECONNABORTED' || error.message === 'Network Error';
    },
    retryDelay: (retryCount) => {
        return retryCount * 1000;// Exponential backoff (1s, 2s, 4s)
    },
});

const handleRequest = async (config) => {
    const token = await AccessLocalToken()
    if (token) {
        config.headers.authorization = token;
    }
    return config;
};

const handleResponse = (response) => {
    return Promise.resolve(response);
};

// Token Refresh Logic
let isRefreshing = false;
let refreshSubscribers = [];

// Add subscribers for requests waiting on token refresh
const subscribeTokenRefresh = (cb) => {
    refreshSubscribers.push(cb);
};

// Notify subscribers once the token is refreshed
const onRrefreshedToken = (token) => {
    refreshSubscribers.forEach((cb) => cb(token));
    refreshSubscribers = [];
};

//TODO:: Will create logic for user to logout automatically once the token is expired
const logoutHandlerTokenExpired = ()=>{
    /*Swal.fire({
        icon: 'error',
        title: 'Forbidden',
        text: message,
    }).then(() => {
        // window.location.href = '/logout'; // Uncomment if logout is needed
    });*/
}

const handleError = async (error) => {
    const originalRequest = error.config;

    if (error.code === 'ECONNABORTED' || error.message === 'Network Error') {
        Swal.fire({
            icon: 'error',
            title: 'Server Unavailable',
            text: 'The server is currently unavailable. Please try again later.',
        });
    }else if (error.response) {
        const { title, message } = error.response.data;

        switch (error.response.status) {
            case 400:
                Swal.fire({
                    title: title || 'Error',
                    html: `<p><strong>${message}</strong></p>`,
                    icon: 'error',
                });
                break;

            case 404:
                Swal.fire({
                    icon: 'error',
                    title: 'Not Found',
                    text: 'The requested resource was not found.',
                });
                break;

            case 403:
                const errorMessage = message || '';

                if (errorMessage.includes('Token expired') || errorMessage.includes('Token invalid')) {
                    originalRequest._retry = true;

                    if (!isRefreshing) {
                        isRefreshing = true;

                        try {
                            const refreshResponse = await AxiosHelper.post('/users/auth/refreshtoken', {
                                refreshToken: await AccessLocalToken(),
                            });

                            const newAccessToken = refreshResponse?.data;
                            UpdateLocalAccessToken(newAccessToken);

                            isRefreshing = false;
                            onRrefreshedToken(newAccessToken); // Notify waiting requests

                            return AxiosHelper(originalRequest); // Retry original request
                        } catch (refreshError) {
                            isRefreshing = false;

                            Swal.fire({
                                icon: 'error',
                                title: 'Session Expired',
                                text: 'Your session has expired. Please log in again.',
                            });

                            return Promise.reject(refreshError);
                        }
                    }

                    // Queue requests while token is being refreshed
                    return new Promise((resolve) => {
                        subscribeTokenRefresh((newToken) => {
                            originalRequest.headers.authorization = `Bearer ${newToken}`;
                            resolve(AxiosHelper(originalRequest));
                        });
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Unauthorized',
                        text: errorMessage || 'You are not authorized to access this resource.',
                    });
                }
                break;

            default:
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: message || 'An unexpected error occurred.',
                });
                break;
        }
    } else {
        // Handle network errors or other unexpected cases
        console.error('Network Error:', error.message);
        Swal.fire({
            icon: 'error',
            title: 'Network Error',
            text: 'An error occurred. Please check your network connection and try again.',
        });
    }

    return Promise.reject(error);
};

AxiosHelper.interceptors.request.use(handleRequest, (error) => Promise.reject(error));
AxiosHelper.interceptors.response.use(handleResponse, handleError);

export default AxiosHelper;
