import React, { useContext, useEffect, useState } from 'react';
import { GlobalStore } from '../../../StateStores';
import { getInvitationTemplatessAct, setEventTemplatessAct } from '../../../Actions/Masters/InvitationTemplatesActs';
import { Button, Card, CardBody, CardFooter, CardText, CardTitle, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClipboardList, faSpinner, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SERVER_BASE_URL } from '../../../Helpers/AxiosHelper';
import { toast } from 'react-toastify';
import InvitationTemplateUpload from './InvitationTemplateUpload';

const InvitationTemplateViewer = ({selectedEvent})=>{
    const [templates, setTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);

    const {
        MasterDispatch,
        MasterState:{
            getInvitationTemplates:{data:templatesData,error,loading},
            setEventTemplate:{data:eventTemplateData,error:eventTemplateErro,loading:eventTemplateLoading},
            getEvents:{data:eventData}
        }} = useContext(GlobalStore);

        const updatedEventData = eventData.find(event => event.id === selectedEvent.id);

    
    useEffect(()=>{
        function fetchTemplates(){
            getInvitationTemplatessAct({})(MasterDispatch)((res)=>{
                if(res.status === 'Success'){
                    setTemplates(res.data)
                }
            })
        }

        fetchTemplates();
    },[])

    const handleDeleteTemplate = (templateId)=>{

    }

    const handleTemplateAction = (template)=>{

        setSelectedTemplateId(template.template_id)
        
        setEventTemplatessAct({
            ...template,
            template_id:updatedEventData.template_id === template.template_id? null:template.template_id,
            event_id:selectedEvent?.id})(MasterDispatch)((res)=>{
                if(res.status === 'Success'){
                    toast.success(res.message)
                }else{
                    toast.error(res.message)
                }

                setSelectedTemplateId(null)
        })
    }


    return(
        <React.Fragment>

            {selectedEvent?.is_event_passed ? (
                <small className='text-danger'>{selectedEvent?.event_status}</small>
            ):(
                <InvitationTemplateUpload event={selectedEvent}/>
            )}
            
            <Row>
                <Col>
                    <h5>Recommended Templates</h5>
                    <hr/>
                </Col>
            </Row>
            <Row>
                {(templates || []).map((template) => (
                    <Col md={4} key={template.template_id} className="mb-4">
                        <Card className="template-card rounded-0 p-0">
                            
                            <CardBody className='p-0'>
                            <div className="template-image-container">
                                <img
                                    src={`${SERVER_BASE_URL}/cdn-template-sample/${encodeURIComponent(template.template_image)}`}
                                    crossorigin="anonymous"
                                    alt={template.template_name} className="template-image card-img-top" />
                                {updatedEventData.template_id === template.template_id && (
                                    <Button
                                        color="success"
                                        className="custom-button select-template-btn">
                                        <FontAwesomeIcon
                                            size="4x"
                                            spin={eventTemplateLoading && selectedTemplateId === template.template_id}
                                            icon={eventTemplateLoading && selectedTemplateId === template.template_id ? faSpinner : faCheck}
                                        />
                                    </Button>
                                )}
                            </div>
                                <CardText className='p-1 text-capitalize fs-'>{template.template_name}</CardText>
                                {/* <Button 
                                    color="danger" 
                                    outline
                                    size='sm'
                                    className='rounded-0 custom-button ms-1'
                                    onClick={() => handleDeleteTemplate(template.template_id)}
                                    >
                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                </Button> */}
                            </CardBody>
                            <CardFooter className='bg-white p-0 border-0'>
                                <Button 
                                    color="danger" 
                                    outline
                                    disabled={eventTemplateLoading && selectedTemplateId === template.template_id}
                                    className='rounded-0 custom-button w-100'
                                    onClick={() => handleTemplateAction(template)}
                                    >
                                    <FontAwesomeIcon
                                        icon={updatedEventData.template_id === template.template_id ? faTimes : faCheck} 
                                    /> {updatedEventData.template_id === template.template_id ? 'Deselect' : 'Select'}
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    )
}

export default InvitationTemplateViewer;