import { faClipboardList, faEdit, faEye, faStickyNote, faTicket, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import { userCan } from '../../Helpers/permissionHandler';
import { ACCESS_EVENT_DASHBOARD, CREATE_TICKET_TYPE, DELETE_EVENT, EDIT_EVENT, SET_EVENT_CARD_TEMPLATE } from '../../Constants/PermissionTypes';

const EventCardList = ({event,setlectEventAct})=>{
    const formattedDate = moment(event.from_date).format('MMM DD YYYY').toUpperCase();
  
    const [month, day, year] = formattedDate.split(' ');

    return(
        <React.Fragment>
            <div className="event-card-list mt-2">
                <div className="event-date-list">
                    <div className="month-list">{month}</div>
                    <div className="day-list">{day}</div>
                    <div className="year-list">{year}</div>
                </div>
                <div className="event-details-list">
                    <div className="event-title-list">{event?.event_name}</div>
                    <div className="event-host-list">Hosted by {event?.createdby}</div>
                    <Badge className='yellow-background fs-color-black'>{event.cat_name}</Badge>
                </div>
                {/* <button className="icon-holder-btn">
                    <FontAwesomeIcon icon={faTrash}/>
                </button> */}

                <div className="d-flex justify-content-end me-1">
                    {userCan(EDIT_EVENT) ? (
                        <Button
                            color="primary"
                            outline
                            className="icon-holder-btn"
                            title="Edit event"
                            onClick={(e) => { setlectEventAct(event, 'event') }}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    ) : null}

                    {userCan(CREATE_TICKET_TYPE) ? (
                        <Button
                            color="dark"
                            outline
                            className="icon-holder-btn ms-1"
                            title="Ticket type"
                            onClick={(e) => { setlectEventAct(event, 'ticket_types') }}
                        >
                            <FontAwesomeIcon icon={faStickyNote} />
                        </Button>
                    ) : null}

                    <Button
                        color="success"
                        outline
                        className="icon-holder-btn ms-1"
                        title="Sell Ticket/Invation card"
                        onClick={(e) => { setlectEventAct(event, 'sell_ticket') }}
                    >
                        <FontAwesomeIcon icon={faTicket} />
                    </Button>

                    {userCan(ACCESS_EVENT_DASHBOARD) ? (
                        <NavLink
                            to="/dashboard/events/viewer"
                            // style={{ border: '1px solid' }}
                            state={{ event: event }}
                            className="
                            border-1 
                            icon-holder-btn 
                            ms-1 d-flex align-items-center justify-content-center"
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </NavLink>
                    ) : null}

                    {userCan(SET_EVENT_CARD_TEMPLATE) ? (
                        <Button
                            color="success"
                            outline
                            className="icon-holder-btn ms-1"
                            title="Ticket/Invation card template"
                            onClick={(e) => { setlectEventAct(event, 'event_template') }}
                        >
                            <FontAwesomeIcon icon={faClipboardList} />
                        </Button>
                    ) : null}

                    {userCan(DELETE_EVENT) ? (
                        <Button
                            color="danger"
                            outline
                            className="icon-holder-btn ms-1"
                            title="Delete event"
                            size="sm"
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    ) : null}

                </div>
            </div>
        </React.Fragment>
    )
}

export default EventCardList;