import React from "react";
import { ReactSession } from 'react-client-session';
import { Navigate } from "react-router-dom";
// import ErrorAuth from "../components/RootComp/ErrorAuth";
import { getLocalAccessPermissions } from "./LocalService";



/**
 * function that will provide the permitted menus/option
 * @returns Array()
 */
export const getPermittedMenuList = async ()=>{
    return await ReactSession.get('permissions');
}


/**
 * Function that will redirect user to assigned screen
 * @param {JSON} param LoggedUserProfile
 * @returns location
 */
export const RouteAuth = ({LoggedUserProfile})=>{
    // return <Navigate to={LoggedUserProfile?.baseroute} />
    return <Navigate to='/dashboard' />
}

export const getBaseRoute = () => {
    try {
        //get current URL
        const currentUrl = window.location.pathname;

        const path = new URL(currentUrl, window.location.origin).pathname;
        const segments = path.split('/').filter(segment => segment);
        return segments.length > 0 ? `/${segments[0]}` : '/';
    } catch (error) {
        console.error('Error extracting base route:', error);
        return '/';
    }
};

const hasRight = (right) => {
    // return rights.includes(right);
};


/**
 * Function to check if the user has permission for a specific action.
 * @param {Object} param0 - { route: string, action: string }
 * @returns {boolean} - True if the action is permitted, otherwise false.
 */
export const HasPermission = ({ route = "", action }) => {
    try {
        // Get the base route (current route or provided)
        const baseRoute = route || getBaseRoute();

        // Retrieve the list of permissions
        const permissions = getLocalAccessPermissions();

        // If no permissions are stored, deny access
        if (!permissions) {
            return false;
        }

        // Find the permissions for the specific route
        const permittedList = permissions.find((item) => item.base_route === baseRoute);

        // If no permissions exist for the route, deny access
        if (!permittedList) {
            return false;
        }

        // Check if the action is included in the permissions' right list
        return permittedList.right?.includes(action) || false;
    } catch (error) {
        console.error('Error checking permissions:', error);
        return false;
    }
};

/**
 * Function to check if the user has a specific permission.
 * @param {string} action - The permission string to check (e.g., 'Create Event').
 * @returns {boolean} - True if the user has the permission, otherwise false.
 */
export const userCan = (action) => {
    try {
        // Retrieve the permissions array from local storage
        const permissions = getLocalAccessPermissions();

        // If no permissions exist or it's not an array, deny access
        if (!Array.isArray(permissions)) {
            return false;
        }

        // Check if the action exists in the permissions array
        return permissions.includes(action);
    } catch (error) {
        console.error('Error checking user permissions:', error);
        return false;
    }
};


/**
 * Function to check if the user has at least one of the specified permissions.
 * @param {string[]} actions - An array of permission constants to check.
 * @returns {boolean} - True if at least one permission is available, otherwise false.
 */
export const userCanAny = (actions) => {
    try {
        // Retrieve the permissions array from local storage
        const permissions = getLocalAccessPermissions();

        // If no permissions exist or it's not an array, deny access
        if (!Array.isArray(permissions)) {
            return false;
        }

        // Check if at least one action exists in the permissions array
        return actions.some(action => permissions.includes(action));
    } catch (error) {
        console.error('Error checking user permissions:', error);
        return false;
    }
};



/**
 * Use to protect the protected routes, unless user logged and permitted to access.
 * @param {*} param0 
 * @returns 
 */
export const Protected = ({isSignedIn,route,action,children, bypass })=>{
    // Check if the user is signed in
    if(!isSignedIn) {
       return <Navigate to="/" replace/>
    }

    // If bypass is true, do not perform any checks, and allow access
    if (bypass) {
        return children;
    }

    // Check if the user has permission for the specific route/action
    const hasPermission = userCan(action);

    if (!hasPermission) {
        // If the user is not permitted, you can show an error page or redirect.
        return <Navigate to="/unauthorized" replace />;
    }

    // If user is signed in and has permission, render the children (protected content)
    return children;


}