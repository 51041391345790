import React, { useContext, useEffect, useState } from 'react';
import { GlobalStore } from '../../../StateStores';
import { getEventTicketTypes, orderEventTicketTypes } from '../../../Actions/Masters/TicketTypesActs';
import { Button, Card, CardBody, CardFooter, CardSubtitle, CardTitle, Col, Container, Input, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import QRCode from 'qrcode.react';
import AxiosHelper from '../../../Helpers/AxiosHelper';
import UserMasterSearch from '../Users/UserMasterSearch';

const OrderTicketsForm = ({selectedEvent})=>{
    const [eventTicketTypes, setEventTicketTypes] = useState([]);
    const [selectedTickets, setSelectedTickets] = useState({});
    const [qrCodes, setQrCodes] = useState([]);
    const [purchaseModal, setPurchaseModal] = useState(false);
    const [showTickets, setShowTickets] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [selectedUser, setSelectedUser] = useState({});

    const {
        MasterDispatch,
        MasterState:{
            orderEventTicketTypes:{data,error,loading},
            getEventTicketTypes:{data:ticketTypeData,error:ticketTypeError,loading:ticketTypeLoading},
        }} = useContext(GlobalStore);


    useEffect(()=>{
        function fetchEventTicketTypes(){
            if(selectedEvent?.id !== undefined){
                getEventTicketTypes({event_id:selectedEvent?.id})(MasterDispatch)((res)=>{
                    if(res.status === 'Success'){
                        setEventTicketTypes(res.data)
                    }
                })
            }
        }
        fetchEventTicketTypes()
    },[])

    const handleQuantityChange = (ticketTypeId, change) => {
        setSelectedTickets(prevState => {
            const newQuantity = (prevState[ticketTypeId] || 0) + change;
            return {
                ...prevState,
                [ticketTypeId]: Math.max(0, newQuantity)
            };
        });
    };

    const isPurchaseDisabled = !Object.values(selectedTickets).some(
        quantity => quantity > 0
      ) || !selectedUser?.value;

    const handleOrderTicket = ()=>{
        const purchaseData = Object.keys(selectedTickets).map(ticketTypeId => ({
            ticketTypeId,
            quantity: selectedTickets[ticketTypeId]
        }));

        if(!isPurchaseDisabled){
            orderEventTicketTypes({
                user_id:selectedUser?.value,
                event_id:selectedEvent?.id,
                tickets:purchaseData})(MasterDispatch)((res)=>{
                if(res.status === 'Success'){
                    setQrCodes(res.data)

                    setEventTicketTypes([]);
                    setShowTickets(false);
                }else{
                    toast.error(res.message)
                }
            })
        }

    }

    const handleDownloadTemplate = () => {
        AxiosHelper.get('/event/download-template', { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ticket_template.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error downloading template:', error);
            });
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUploadTickets = () => {
        if (!selectedFile) {
            alert('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        AxiosHelper.post('/event/upload-tickets', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            setSuccessMessage('Tickets uploaded and processed successfully!');
            // Handle any additional logic after successful upload
        })
        .catch(error => {
            console.error('Error uploading tickets:', error);
            // Handle error response
        });
    };

    return(
        <React.Fragment>
            <Container>
                {/* <Row>
                    <Col>
                        <h3>Purchase Tickets</h3>
                        <Button color="primary" onClick={handleDownloadTemplate}>
                            Download Excel Template
                        </Button>
                        <Input type="file" onChange={handleFileChange} className="mt-2" />
                        <Button color="success" className="mt-2" onClick={handleUploadTickets}>
                            Upload Tickets
                        </Button>
                        {successMessage && <Alert color="success" className="mt-3">{successMessage}</Alert>}
                    </Col>
                </Row> */}
                <h4>{selectedEvent.event_name}</h4>

                <UserMasterSearch onSelect={setSelectedUser} evetData={selectedEvent}/>

                <h5>Purchase Tickets</h5>

                    {loading && (
                    <div className='d-flex justify-content-center p-4'>
                        <Spinner/>
                    </div>)}

                    {!loading && qrCodes.length > 0 && (
                        <div className='d-flex justify-content-center'>
                            <div>
                                {(qrCodes || []).map((qrcode,index) => (
                                    <Card className='mb-2'>
                                        <CardBody>
                                            <QRCode value={qrcode.encodedQrcode}/>
                                            {/* <img src={qrcode.qrCode} alt={`QR Code ${index}`} className="img-fluid" /> */}
                                            </CardBody>
                                        <CardFooter>{`${qrcode.quantity} X ${qrcode.ticketTypeName}`}</CardFooter>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    )}

                    {showTickets && (
                        
                        <>
                        {(eventTicketTypes || []).map(ticketType => (
                            <Row>
                                <Col key={ticketType.event_ticket_type_id} className="mb-3">
                                    <Card className='rounded-0'>
                                        <CardBody>
                                            <CardTitle tag="h5">{ticketType.type_name}</CardTitle>
                                            <Row>
                                                <Col>
                                                    <CardSubtitle className="mb-2 text-muted">Price: {ticketType.price}</CardSubtitle>
                                                    <CardSubtitle className="mb-2 text-muted">Available: {ticketType.quantity}</CardSubtitle>
                                                </Col> 
                                                <Col md={2}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Button
                                                            size="sm" 
                                                            color='primary'
                                                            outline
                                                            className='rounded-0'
                                                            onClick={() => handleQuantityChange(ticketType.event_ticket_type_id, -1)} 
                                                            disabled={!selectedTickets[ticketType.event_ticket_type_id]}
                                                        ><FontAwesomeIcon icon={faMinus}/>
                                                        </Button>
                                                        <Input
                                                            type="number" 
                                                            value={selectedTickets[ticketType.event_ticket_type_id] || 0} 
                                                            readOnly 
                                                            size="sm"
                                                            className="text-center mx-2 rounded-0"
                                                            style={{ width: '50px' }}
                                                        />
                                                        <Button 
                                                            size="sm" 
                                                            outline
                                                            color='success'
                                                            className='rounded-0'
                                                            onClick={() => handleQuantityChange(ticketType.event_ticket_type_id, 1)}
                                                        ><FontAwesomeIcon icon={faPlus}/>
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        ))}

                        {selectedEvent.is_event_passed ? (
                            <small className='text-danger'>{selectedEvent.event_status}</small>
                        ):(
                            <div className='d-flex justify-content-end'>
                                <Button 
                                    color="dark" 
                                    className="custom-button rounded-0"
                                    disabled={isPurchaseDisabled}
                                    onClick={handleOrderTicket}><FontAwesomeIcon icon={faSave}/> Save</Button>
                            </div>
                        )}
                        </>
                        
                    )}

            </Container>
        </React.Fragment>
    )
}

export default OrderTicketsForm;